import { useEffect, useRef, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';

import gsap from 'gsap';
import { ScrollTrigger, MotionPathPlugin, ScrollToPlugin } from 'gsap/all';

import { LandingDemo } from './components/LandingDemo';
import { LandingFaq } from './components/LandingFaq';
import { LandingFooter } from './components/LandingFooter';
import { LandingHeader } from './components/LandingHeader';
import { LandingHelp } from './components/LandingHelp';
import { LandingHero } from './components/LandingHero';
import { LandingOffer } from './components/LandingOffer';
import { LandingService } from './components/LandingService';
import { LandingPrice } from './components/LandingPrice';

import stylesCommon from './Landing.module.scss';
import { LandingHelpsWith } from './components/LandingHelpsWith';
import { LandingModalBid } from './components/LandingModalBid';
import { LandingModalPhone } from './components/LandingModalPhone';
import { LandingModalAuth } from './components/LandingModalAuth';
import { LandingFunctionality } from './components/LandingFunctionality';
import { LANDING_SECTION_CLASS, snapScrollAllowed } from './util';
import { LangingVideo } from './components/LangingVideo';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, ScrollToPlugin);

const eventTypes = ['scroll', 'wheel', 'touchmove', 'pointermove'];

const scrolling = {
  enabled: true,
  isUserScrolling: false,
  supposedNextSection: null as HTMLElement | null,
  scrolledAfterMouseDown: false,
  initialized: false,
  prevent: (e: Event) => {
    e.preventDefault();
  },
  setScrollPrevented: (value: boolean) => {
    if (value) {
      eventTypes.forEach((e, i) => {
        (i ? document : window).addEventListener(e, scrolling.prevent, { passive: false });
      });
    } else {
      eventTypes.forEach((e, i) => {
        (i ? document : window).removeEventListener(e, scrolling.prevent);
      });
    }
  },
  disable() {
    if (scrolling.enabled) {
      scrolling.enabled = false;
      // window.addEventListener('scroll', gsap.ticker.tick, { passive: true });
      scrolling.setScrollPrevented(true);
    }
  },
  enable() {
    if (!scrolling.enabled) {
      scrolling.enabled = true;
      // window.removeEventListener('scroll', gsap.ticker.tick);
      scrolling.setScrollPrevented(false);
    }
  },
};

const disableScrollDown = ['landing-price'];
const disableScrollUp = ['landing-functionality'];

const Landing = () => {
  const windowWidth = useWindowWidth();
  const [isBidModalOpen, setIsBidModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const demoRef = useRef(null);
  const timelines = useRef<any[]>([]);

  const getSections = () => {
    return gsap.utils.toArray<HTMLElement>(`.${LANDING_SECTION_CLASS}`);
  };

  function goToSection(section: HTMLElement) {
    if (scrolling.enabled) {
      // skip if a scroll tween is in progress
      scrolling.disable();
      gsap.to(window, {
        scrollTo: { y: section, autoKill: false },
        onComplete: () => {
          setTimeout(() => {
            scrolling.enable();
          }, 300);
        },

        duration: 0.8,
      });
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    if (!e.button) {
      scrolling.isUserScrolling = true;
    }
  };

  const onMouseUp = () => {
    scrolling.isUserScrolling = false;
    if (scrolling.scrolledAfterMouseDown) {
      scrolling.scrolledAfterMouseDown = false;
      // adjustSectionAfterScroll();
    }
  };

  const onScroll = (e: Event) => {
    if (scrolling.isUserScrolling) {
      scrolling.scrolledAfterMouseDown = true;
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', onMouseDown);
    document.addEventListener('pointerup', onMouseUp);
    eventTypes.forEach((e, i) => {
      (i ? document : window).addEventListener(e, onScroll, { passive: false });
    });

    return () => {
      document.removeEventListener('pointerdown', onMouseDown);
      document.removeEventListener('pointerup', onMouseUp);

      eventTypes.forEach((e, i) => {
        (i ? document : window).removeEventListener(e, onScroll);
      });
    };
  }, []);

  const scollToTop = () => {
    const sections = getSections();
    goToSection(sections[0]);
  };

  useEffect(() => {
    setTimeout(() => {
      scrolling.initialized = true;
    }, 500);
  }, []);

  useEffect(() => {
    if (!demoRef.current || !snapScrollAllowed()) {
      return;
    }
    if (!scrolling.enabled) {
      scrolling.enable();
    }
    timelines.current.forEach((item) => {
      item.kill();
    });

    const sections = getSections();

    sections.forEach((section, i) => {
      const timeline = ScrollTrigger.create({
        trigger: section,
        start: 'top+=50 bottom-=1',
        end: 'bottom-=70 top+=1',
        onEnter: () => {
          if (!scrolling.initialized) {
            return;
          }
          if (disableScrollDown.includes(section.id) || scrolling.isUserScrolling) {
            scrolling.supposedNextSection = section;
            return;
          }
          goToSection(section);
        },
        onEnterBack: () => {
          if (!scrolling.initialized) {
            return;
          }
          if (disableScrollUp.includes(section.id) || scrolling.isUserScrolling) {
            scrolling.supposedNextSection = section;
            return;
          }
          goToSection(section);
        },
        scrub: true,
        // preventOverlaps: true,
        // markers: true,
      });

      timelines.current.push(timeline);
    });

    return () => {
      timelines.current.forEach((item) => {
        item.kill();
      });
    };
  }, [demoRef.current, windowWidth]);

  const openBidModal = () => {
    setIsBidModalOpen(true);
  };

  const closeBidModal = () => {
    setIsBidModalOpen(false);
  };

  const openPhoneModal = () => {
    setIsPhoneModalOpen(true);
  };

  const closePhoneModal = () => {
    setIsPhoneModalOpen(false);
  };

  const openAuthModal = () => {
    setIsAuthModalOpen(true);
  };

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  useEffect(() => {
    if (isBidModalOpen || isPhoneModalOpen || isAuthModalOpen || isServiceModalOpen) {
      scrolling.disable();
    } else {
      if (!scrolling.enabled) {
        scrolling.enable();
      }
    }

    return () => {
      if (!scrolling.enabled) {
        scrolling.enable();
      }
    };
  }, [isBidModalOpen, isPhoneModalOpen, isAuthModalOpen, isServiceModalOpen]);

  const goToSectionById = (id: string, event: React.MouseEvent) => {
    event.preventDefault();
    const sections = getSections();
    const section = sections.find((item) => item.id === id);
    if (!section) {
      return;
    }
    goToSection(section);
  };

  return (
    <div className={stylesCommon.fakeBody}>
      <div>
        <LandingHeader openPhoneModal={openPhoneModal} openAuthModal={openAuthModal} goToSection={goToSectionById} />
        <LandingHero openBidModal={openBidModal} goToSection={goToSectionById} />
        <LandingOffer />
        <LangingVideo />
        <LandingService openBidModal={openBidModal} setIsServiceModalOpen={setIsServiceModalOpen} />
        <LandingHelpsWith />
        <LandingHelp />
        <LandingFunctionality />
        <LandingPrice openPhoneModal={openPhoneModal} openBidModal={openBidModal} />
        <LandingDemo openBidModal={openBidModal} ref={demoRef} />
        <LandingFaq openPhoneModal={openPhoneModal} />
        <LandingFooter openPhoneModal={openPhoneModal} openBidModal={openBidModal} scollToTop={scollToTop} />
        <LandingModalBid open={isBidModalOpen} onClose={closeBidModal} />
        <LandingModalPhone open={isPhoneModalOpen} onClose={closePhoneModal} />
        <LandingModalAuth open={isAuthModalOpen} onClose={closeAuthModal} openBidModal={openBidModal} />
      </div>
    </div>
  );
};

export default Landing;
