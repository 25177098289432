export const LandingRegistryIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 37" fill="none">
      <rect
        x="19.5117"
        y="23.8496"
        width="6.52869"
        height="6.52869"
        transform="rotate(135 19.5117 23.8496)"
        fill="white"
      />
      <rect
        x="18.418"
        y="5.19336"
        width="4.93878"
        height="4.93878"
        transform="rotate(135 18.418 5.19336)"
        fill="white"
      />
      <rect
        x="10.2305"
        y="23.8184"
        width="6.52869"
        height="6.52869"
        transform="rotate(135 10.2305 23.8184)"
        fill="white"
      />
      <rect
        x="10.2305"
        y="5.19336"
        width="6.52869"
        height="6.52869"
        transform="rotate(135 10.2305 5.19336)"
        fill="white"
      />
      <rect
        x="18.1094"
        y="33.209"
        width="4.54524"
        height="4.54524"
        transform="rotate(135 18.1094 33.209)"
        fill="white"
      />
      <rect
        x="18.3867"
        y="14.5527"
        width="4.93798"
        height="4.93798"
        transform="rotate(135 18.3867 14.5527)"
        fill="white"
      />
      <rect
        x="8.75781"
        y="33.1289"
        width="4.44521"
        height="4.44521"
        transform="rotate(135 8.75781 33.1289)"
        fill="white"
      />
      <rect
        x="10.2305"
        y="14.5059"
        width="6.52869"
        height="6.52869"
        transform="rotate(135 10.2305 14.5059)"
        fill="white"
      />
      <rect
        x="25.3398"
        y="23.8828"
        width="1.64646"
        height="1.64646"
        transform="rotate(135 25.3398 23.8828)"
        fill="white"
      />
      <rect
        x="26.5039"
        y="5.25781"
        width="3.29263"
        height="3.29263"
        transform="rotate(135 26.5039 5.25781)"
        fill="white"
      />
      <rect
        x="34.6758"
        y="23.8828"
        width="1.64646"
        height="1.64646"
        transform="rotate(135 34.6758 23.8828)"
        fill="white"
      />
      <rect
        x="30.0195"
        y="28.5137"
        width="1.64646"
        height="1.64646"
        transform="rotate(135 30.0195 28.5137)"
        fill="white"
      />
      <rect
        x="21.8711"
        y="28.5137"
        width="1.64646"
        height="1.64646"
        transform="rotate(135 21.8711 28.5137)"
        fill="white"
      />
      <rect
        x="35.8164"
        y="5.25781"
        width="3.25727"
        height="3.25727"
        transform="rotate(135 35.8164 5.25781)"
        fill="white"
      />
      <rect
        x="25.3398"
        y="33.1699"
        width="1.64646"
        height="1.64646"
        transform="rotate(135 25.3398 33.1699)"
        fill="white"
      />
      <rect x="26.5273" y="14.5449" width="3.328" height="3.328" transform="rotate(135 26.5273 14.5449)" fill="white" />
      <rect
        x="34.6758"
        y="33.1699"
        width="1.64646"
        height="1.64646"
        transform="rotate(135 34.6758 33.1699)"
        fill="white"
      />
      <rect
        x="35.8398"
        y="14.5449"
        width="3.29263"
        height="3.29263"
        transform="rotate(135 35.8398 14.5449)"
        fill="white"
      />
      <rect
        x="31.1836"
        y="9.88867"
        width="3.29263"
        height="3.29263"
        transform="rotate(135 31.1836 9.88867)"
        fill="white"
      />
      <rect
        x="31.1836"
        y="19.2012"
        width="3.29263"
        height="3.29263"
        transform="rotate(135 31.1836 19.2012)"
        fill="white"
      />
      <rect
        x="23.0352"
        y="19.2012"
        width="3.29263"
        height="3.29263"
        transform="rotate(135 23.0352 19.2012)"
        fill="white"
      />
      <rect
        x="21.8711"
        y="9.88867"
        width="3.29263"
        height="3.29263"
        transform="rotate(135 21.8711 9.88867)"
        fill="white"
      />
    </svg>
  );
};
